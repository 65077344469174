import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
    TitleSpan,
    QuickViewTitle,
    linkStyle
} from './style'

class QuickViews extends Component {

    render() {
        const {blogList, setBlogId, closeBoardCallback} = this.props
        return (
            <Fragment>
                <TitleSpan className='dashboard-title-span' style={{ display: "block", marginBottom: "12px" }}>QUICK VIEWS</TitleSpan>
                <ul style={{ whiteSpace: "nowrap" }}>
                    {
                        blogList.map((blog) =>
                            <QuickViewTitle key={`quick-view-blog-${blog.id}`}>
                                <Link to={`/detail/${blog.id}`} className='blog-title-display' style={linkStyle} onClick={() => { window.scrollTo(0, 0); setBlogId(parseInt(blog.id)); closeBoardCallback() }}>
                                    {blog.title}
                                </Link>
                            </QuickViewTitle>
                        )
                    }
                </ul>
            </Fragment>
        )
    }
}

export default QuickViews