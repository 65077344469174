export const NONE_DISPLAY = null
export const PAGING_OPTIONS = [5, 10, 20, 50]
export const MOBILE_LAYOUT_THRESHOLD = 992
export const TAG_DISPLAY = '/home/TAG_DISPLAY'
export const TITLE_DISPLAY = '/home/TITLE_DISPLAY'
export const FILL_TAG_LIST = '/home/FILL_TAG_LIST'
export const FILL_BLOG_LIST = '/home/FILL_BLOG_LIST'
export const CHANGE_PAGE_NUM = '/home/CHANGE_PAGE_NUM'
export const SET_TAG_NAME = '/home/SET_TAG_NAME'
export const SEARCH_TITLE = '/home/SEARCH_TITLE'
export const BLOGS_PER_PAGE = '/home/BLOGS_PER_PAGE'
export const UPDATE_Y_SCROLL = '/home/UPDATE_Y_SCROLL'
export const SET_CHANNEL = '/home/SET_CHANNEL'